import React from "react";
import { $P1, $P2, $P3, $P4 } from "./Text.sc";

export interface IP {
  size?: "1" | "2" | "3" | "4";
  color?: string;
  fontWeight?: number;
  margin?: string;
  children: React.ReactNode;
}

const P: React.FC<IP> = ({
  size = "2",
  color = "baseDark",
  fontWeight = 400,
  margin = "0",
  children,
}) => {
  switch (size) {
    case "1":
      return (
        <$P1 color={color} fontWeight={fontWeight} margin={margin}>
          {children}
        </$P1>
      );
    case "2":
      return (
        <$P2 color={color} fontWeight={fontWeight} margin={margin} as="p">
          {children}
        </$P2>
      );
    case "3":
      return (
        <$P3 color={color} fontWeight={fontWeight} margin={margin} as="p">
          {children}
        </$P3>
      );
    default:
      return (
        <$P4 color={color} fontWeight={fontWeight} margin={margin} as="p">
          {children}
        </$P4>
      );
  }
};

export default P;
