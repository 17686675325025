import React from "react";
import Select, { components } from "react-select";

import { BaseLabel } from "@styles/components/elements/Inputs";

import Icon from "@atoms/Icon";
import { colors } from "@styles/colors";

const customStyles = {
  container: (provided, state) => ({
    ...provided,
    width: state.selectProps.width,
    height: state.selectProps.height,
    color: state.selectProps.color,
    background: "#ffffff",
    overflow: scroll,
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    "&:hover": {
      cursor: "pointer",
    },
  }),
  menu: (styles, state) => ({
    ...styles,
    width: state.selectProps.width,
    border: "2px solid #E6ECF1",
    borderRadius: "10px",
  }),
  control: (styles, state) => ({
    ...styles,
    height: state.selectProps.height,
    borderColor: state.selectProps.isValid
      ? colors.validationRed
      : state.isFocused
      ? state.selectProps.valueColor ? colors.pastelDarkBlue : "#0c8dff"
      : "#E6ECF1",
    borderWidth: "2px",
    boarderRadius: "4px",
    "&:hover": {
      borderColor: "#cccccc",
    },
  }),
  option: (styles, state) => ({
    paddingTop: "5px",
    paddingBottom: "5px",
    paddingLeft: "16px",
    paddingRight: "16px",
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: state.selectProps.fontSize ? state.selectProps.fontSize : "18px",
    lineHeight: state.selectProps.lineHeight
      ? state.selectProps.lineHeight
      : "24px",
    alignItems: "center",
    borderRadius: "5px",
    color: state.selectProps.valueColor
    ? state.selectProps.valueColor
     : colors.baseDark,
    "&:hover": {
      backgroundColor: state.selectProps.valueColor ? colors.pastelDarkBlue : "#0c8dff",
      color: "#ffffff",
    },
    "&:focus": {
      backgroundColor: state.selectProps.valueColor ? colors.pastelDarkBlue : "#0c8dff",

    },
  }),

  placeholder: (styles, state) => {
    return {
      ...styles,
      fontFamily: "Open Sans",
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: state.selectProps.fontSize
        ? state.selectProps.fontSize
        : "18px",
      lineHeight: state.selectProps.lineHeight
        ? state.selectProps.lineHeight
        : "24px",
      alignItems: "center",
      color: "#939393",
    };
  },
  singleValue: (base, state) => ({
    ...base,
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: state.selectProps.fontSize ? state.selectProps.fontSize : "18px",
    lineHeight: state.selectProps.lineHeight
      ? state.selectProps.lineHeight
      : "24px",
    alignItems: "center",
    color: "#939393",
  }),
};
import facebookIconWhite from "../../../assets/icons/facebookIconWhite.png";
import facebookIconGray from "../../../assets/icons/facebookIconGray.png";
import facebookIcon from "../../../assets/icons/facebookIcon.png";

export const facebookSelect = {
  option: (provided, state) => ({
    ...provided,
    "#name": {
      fontSize: "16px",
      fontWeight: "400",
      lineHeight: "16px",
      letterSpacing: "0.5px",
      marginBottom: "3px",
    },
    "#location": {
      fontSize: "10px",
      fontWeight: "400",
    },
    display: "flex",
    width: "600px",
    paddingLeft: "40px",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "10px center",
    backgroundImage: state.isSelected
      ? `url('${facebookIconWhite}')`
      : `url('${facebookIconGray}')`,
    color: colors.black,
    backgroundColor: state.isSelected ? colors.pastelDarkBlue : colors.white,
    "&:hover": {
      "#name": {
        fontSize: "16px",
        fontWeight: "bold",
      },
      paddingTop: "7px",
      paddingLeft: "40px",
      backgroundImage: `url('${facebookIconWhite}')`,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "10px center",
      backgroundColor: colors.pastelDarkBlue,
      color: colors.white,
    },
  }),
  placeholder: (styles, state) => {
    return {
      ...styles,
      fontFamily: "Open Sans",
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: state.selectProps.fontSize
        ? state.selectProps.fontSize
        : "18px",
      lineHeight: state.selectProps.lineHeight
        ? state.selectProps.lineHeight
        : "24px",
      alignItems: "center",
      color: "#939393",
    };
  },
  singleValue: (base, state) => ({
    ...base,
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: state.selectProps.fontSize ? state.selectProps.fontSize : "18px",
    lineHeight: state.selectProps.lineHeight
      ? state.selectProps.lineHeight
      : "24px",
    alignItems: "center",
    color: colors.black,

    paddingLeft: "40px",
    backgroundImage: `url('${facebookIcon}')`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "10px center",
    "#location": {
      display: "none",
    },
    "#name": {
      fontWeight: "normal",
      fontSize: "18px",
    },
  }),
  menu: (styles, state) => ({
    ...styles,
    width: state.selectProps.width,
    border: "2px solid #E6ECF1",
    borderRadius: "10px",
  }),
  control: (styles, state) => ({
    ...styles,
    height: state.selectProps.height,
    borderColor: state.selectProps.isValid
      ? colors.validationRed
      : state.isFocused
      ? "#0c8dff"
      : "#E6ECF1",
    borderWidth: "2px",
    boarderRadius: "4px",
    "&:hover": {
      borderColor: "#cccccc",
    },
  }),
};

export type SelectedProps = {
  value: string;
  label: string;
};

type SelectBaseProps = {
  label: string;
  name: string;
  placeholder: string;
  onChange?: (value: SelectedProps) => void;
  selected?: SelectedProps;
  options: Array<SelectedProps>;
  isValid?: boolean;
  hasBorder?: boolean;
  valueColor?: string;
};

const BaseSelect = (props) => {
  function handleChange(selected: SelectedProps) {
    if (props.onChange === undefined) {
      return;
    }

    props.onChange(selected);
  }

  const DropdownIndicator = (props) => {
    return (
      components.DropdownIndicator && (
        <components.DropdownIndicator {...props}>
          <Icon
            size={24}
            icon={props.selectProps.menuIsOpen ? "chevronUp" : "chevronDown"}
            color="baseDark"
          />
        </components.DropdownIndicator>
      )
    );
  };

  const renderLabel = () => {
    return <BaseLabel style={{ paddingTop: "16px" }}>{props.label}</BaseLabel>;
  };
  return (
    <div>
      {props.label ? renderLabel() : null}
      <Select
        styles={customStyles}
        components={{ IndicatorSeparator: () => null, DropdownIndicator }}
        name={props.name}
        value={props.selected}
        options={props.options}
        onChange={handleChange}
        placeholder={props.placeholder}
        isValid={props.isValid}
        {...props}
      />
    </div>
  );
};

export default BaseSelect;
