import styled from "styled-components";
import { colors } from "@styles/colors";

export const $HorizontalBreak = styled.hr<{
  margin: string;
  color: string;
  height: string;
}>`
  color: ${(props) => props.color};
  margin: ${(props) => props.margin};
  height: ${(props) => props.height};
`;
