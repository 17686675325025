import React, { FunctionComponent as FC, ReactNode } from "react";
import { colors } from "@styles/colors";
import {
  $Button,
  $ButtonText,
  $TextButton,
  $SquareButton,
  $NavButton,
} from "./Button.sc";

export interface IButtonProps {
  type?: "button" | "link";
  shape?: "pill" | "square" | "text" | "nav";
  color?: string;
  customColor?: string;
  children: ReactNode;
  onClick: () => void;
  leftIcon?: string;
  rightIcon?: string;
  disabled?: boolean;
  id?: string;
  active?: boolean;
  testId?: string;
  width?: string;
}

const Button: FC<IButtonProps> = ({
  onClick,
  shape = "pill",
  color = "primary1", //add theme here when ready for color palette change
  customColor,
  children,
  leftIcon,
  rightIcon,
  disabled,
  id,
  active,
  testId,
  width = "auto",
}) => {
  switch (shape) {
    case "square":
      return (
        <$SquareButton
          data-testid={testId ? testId : null}
          onClick={onClick}
          disabled={disabled}
          id={id}
          color={color}
          width={width}
        >
          <$ButtonText>{children}</$ButtonText>
        </$SquareButton>
      );
    case "text":
      return (
        <$TextButton
          data-testid={testId ? testId : null}
          onClick={onClick}
          disabled={disabled}
          id={id}
          color={color}
        >
          {children}
        </$TextButton>
      );
    case "nav":
      return (
        <$NavButton
          data-testid={testId ? testId : null}
          active={active}
          onClick={onClick}
          disabled={disabled}
          id={id}
          color={color}
        >
          {children}
        </$NavButton>
      );
    default:
      return (
        <$Button
          data-testid={testId ? testId : null}
          onClick={onClick}
          disabled={disabled}
          id={id}
          color={color}
          customColor={customColor}
          width={width}
        >
          {leftIcon && <img src={leftIcon} alt="left-icon" />}
          <$ButtonText>{children}</$ButtonText>
          {rightIcon && <img src={rightIcon} alt="right-icon" />}
        </$Button>
      );
  }
};

export default Button;
