import styled from "styled-components";

const $P = styled.p<{
  color: string;
  fontWeight: number;
  margin: string;
}>`
  color: ${(props) => props.theme.colors[props.color]};
  font-weight: ${(props) => props.fontWeight};
  width: fit-content;
  line-height: 150%;
  font-family: Open Sans;
  margin: ${(props) => props.margin};
`;

export const $P1 = styled($P)`
  font-size: 18px;
`;
export const $P2 = styled($P)`
  font-size: 16px;
`;
export const $P3 = styled($P)`
  font-size: 14px;
`;
export const $P4 = styled($P)`
  font-size: 12px;
`;
