import styled from "styled-components";
import { colors } from "@styles/colors";
import { lighten } from "polished";

export const $Button = styled.button<{
  color: string;
  customColor?: string;
  width: string;
}>`
  border: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 24px;
  width: ${({ width }) => width};
  ${(props) =>
    props.customColor
      ? `
  background-color: ${props.disabled ? colors.lightGray : props.customColor};
    &:hover {
      background-color: ${
        props.disabled ? colors.lightGray : lighten(0.1, props.customColor)
      };
      box-shadow: ${
        props.disabled ? "0px" : "0px 8px 24px rgba(48, 49, 51, 0.1)"
      };
    }
    &:active {
      background-color: ${
        props.disabled ? colors.lightGray : lighten(0.1, props.customColor)
      };
      box-shadow: 0px;
    }`
      : `
  background-color: ${
    props.disabled ? colors.lightGray : props.theme.colors[props.color]
  };
    &:hover {
      background-color: ${props.disabled ? colors.lightGray : colors.steelBlue};
      box-shadow: ${
        props.disabled ? "0px" : "0px 8px 24px rgba(48, 49, 51, 0.1)"
      };
    }
    &:active {
      background-color: ${props.disabled ? colors.lightGray : colors.primary1};
      border: 2px solid ${colors.steelBlue}
    }
  `}
  color: ${(props) => props.theme.colors.baseWhite};
  ${(props) => props.theme.font.bodyTwo}
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 28px;
  text-align: right;
  cursor: pointer;
  padding: 8px 10px;
  text-decoration: none;
`;

export const $ButtonText = styled.p`
  padding: 0 8px;
  margin: 0;
  margin-top: -2px;
`;

export const $SquareButton = styled($Button)`
  padding: 0;
  border-radius: 0;
`;

export const $TextButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  ${(props) => props.theme.font.bodyTwo}
  border-radius: 0;
  border: none;
  padding: 0;
  text-decoration: none;
  cursor: pointer;
  &:hover,
  &:active {
    color: ${(props) => (props.disabled ? colors.lightGray : colors.primary1)};
  }
`;

export const $NavButton = styled.button<{ active: boolean }>`
  background-color: ${(props) =>
    props.active ? props.theme.colors.palette["blue100"] : "transparent"};
  padding: 8px;
  text-decoration: none;
  border-radius: 8px;
  cursor: pointer;
  border: 0;
  align-items: center;
  justify-content: center;
  width: 104px;
  p,
  svg {
    fill: ${(props) =>
      props.active
        ? props.theme.colors["primary1"]
        : props.theme.colors["baseDark"]};
    color: ${(props) =>
      props.active
        ? props.theme.colors["primary1"]
        : props.theme.colors["baseDark"]};
  }
  &:hover,
  &:active {
    p,
    svg {
      fill: ${(props) => props.theme.colors["primary1"]};
      color: ${(props) => props.theme.colors["primary1"]};
    }
  }
  &:hover,
  &:active {
    background-color: ${(props) => props.theme.colors.palette["blue100"]};
  }
`;
