import React from "react";
import { colors } from "@styles/colors";
import { $HorizontalBreak } from "./HorizonalBreak.sc";

export interface IBreakProps {
  color?: string;
  margin?: string;
  height?: string;
}

const HorizontalBreak = ({
  height = "1px",
  color = colors.baseLight,
  margin = "48px 0px",
}: IBreakProps) => (
  <$HorizontalBreak color={color} margin={margin} height={height} />
);

export default HorizontalBreak;
